<template>
  <div class="check-stock">
       <el-button @click="handle_export_to_csv" style="width:100%; margin-bottom:5px;" type="success">ייצוא לאקסל</el-button>
       <el-input id="barcode" v-model="barcode" type="number"  placeholder="ברקוד/מקט" />
       <el-button @click="handle_search" style="width:100%; margin-top:5px;" type="primary">חיפוש</el-button>
        <div v-show="show_stock_box" class="check-stock-stocks">
            <p style="font-size:20px">כמות להוספה</p>
            <el-input id="qnt" v-model="stock" type="number"  placeholder="כמות" />
            <el-button @click="handle_save_in_db"  style="width:100%; margin-top:5px;" type="success">שמירה</el-button>
        </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {projectFirestore,firebase} from '../../../firebase/config'
import {alert} from '../../../Methods/Msgs'
import XLSX from "xlsx";
import { onMounted } from '@vue/runtime-core';
export default {
    setup(){
        const show_stock_box = ref(false)
        const error_msg = ref('')
        const validation = ()=>{
            if(!(barcode.value.length >=6 && barcode.value.length <14)){
                error_msg.value = 'עליך להזין ברקוד באורך של בין 6 ל- 14 ספרות'
                return false
            }
            return true
        }
        const barcode = ref('')
        const stock = ref(null)

        const handle_search = async()=>{
            if(!validation()){
                show_stock_box.value = false
                alert('error','שגיאה',error_msg.value)
                .then(()=>{
                    barcode.value = ''
                    document.getElementById('barcode').focus()
                })
            }else{
                show_stock_box.value = true
                setTimeout(() => {
                    document.getElementById('qnt').focus()
                }, 500);
            }
         
        }

        const handle_save_in_db = async()=>{
            const doc = await projectFirestore.collection('Applications').doc('T1gfBS9lxRDeqTiIbHwB').collection('Stock_Count')
                .doc(barcode.value).get()
                if(!doc.exists){
                    await projectFirestore.collection('Applications').doc('T1gfBS9lxRDeqTiIbHwB').collection('Stock_Count')
                    .doc(barcode.value).set({
                        barcode:barcode.value,
                        stock: Number(stock.value)
                    })
                }else{
                    await projectFirestore.collection('Applications').doc('T1gfBS9lxRDeqTiIbHwB').collection('Stock_Count')
                    .doc(barcode.value).update({
                        stock: firebase.firestore.FieldValue.increment(Number(stock.value))
                    })
                }
                alert('success','הצלחה',
                `התווספו בהצלחה ${stock.value} יחידות למוצר ${barcode.value}`)
                .then(()=>{
                    barcode.value = ''
                    stock.value = null
                    show_stock_box.value = false
                    document.getElementById('barcode').focus()
                })
        }

        const handle_export_to_csv = async()=>{
           const docs = await projectFirestore.collection('Applications').doc('T1gfBS9lxRDeqTiIbHwB').collection('Stock_Count').get()
           const records = docs.docs.map(doc=>doc.data())

           let rows = JSON.parse(JSON.stringify(records))
           rows.forEach(row=>{
                row['ברקוד'] = row.barcode
                row['כמות במלאי'] = row.stock
                delete row.barcode
                delete row.stock
           })

            let workSheet = XLSX.utils.json_to_sheet(rows);
            let workbook = XLSX.utils.book_new();
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, "מלאי");
            XLSX.writeFile(workbook, "מלאי.xlsx");
        }

        onMounted(()=>{
            document.getElementById('barcode').focus()
        })
        return{
            barcode,
            handle_search,
            handle_export_to_csv,
            stock,
            handle_save_in_db,
            validation,
            error_msg,
            show_stock_box
        }
    }
}
</script>

<style scoped>
    .check-stock{
        width: 100%;
        max-width: 1200px;
        margin:0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;

    }
    .check-stock-stocks{
        width: 100%;
        height: 200px;
        background: var(--secondary);
        margin-top: 5px;
        color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>